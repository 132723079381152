import { defineStore } from 'pinia';
import { ref } from 'vue';
import { CodeEngineClient } from './base-client';

export const useCodeEngineStore = defineStore('codeEngine', () => {
  const clientId = ref('');
  const apiUrl = ref('');
  const apiKey = ref('');  

  const portalContents = ref([]);
  const marketingContents = ref([]);
  const errorMapping = ref([]);
  const branding = ref({});
  const theme = ref({});

  const currentLanguage = ref('enUS');
  const internalError = ref('');
  
  const codeEngineClient = ref(new CodeEngineClient());
  const getPortalContentByPageName = function(pageName) { 
    let pageContents = portalContents.value.filter(x => x.pageName === pageName && x.languageCode === currentLanguage.value);
    return pageContents;    
  }

  // TODO - Talk to Sara and figure out if we can get currentLanguage right from the browser
  function setCurrentLanguage(languageCode) {
    currentLanguage.value = languageCode;
  }

  const getPortalContentByPageAndContentName = function(pageName, contentName, defaultValue, parameters) {    
    let pageContents = portalContents.value.find(x => x.pageName === pageName && x.contentName === contentName && x.languageCode === currentLanguage.value);
    if(pageContents) {
      let toReturn = pageContents.content;
      if(parameters && parameters.length > 0) {
          for(let i = 0; i < parameters.length; i++) {                
              toReturn = toReturn.replaceAll('%%' + parameters[i].variable + '%%', parameters[i].value);
          }
      }
      return toReturn;    
    } else {
      if(!defaultValue) {
          return 'Content Not Found';
      } else {
          let toReturn = defaultValue;
          if(parameters && parameters.length > 0) {
            for(let i = 0; i < parameters.length; i++) {                
                toReturn = toReturn.replaceAll('%%' + parameters[i].variable + '%%', parameters[i].value);
            }
          }
          return toReturn;
      }
    }
  }

  async function getMarketingContentByPageAndName(portalTypeId, pageName, contentName) {    
    let marketingContent = marketingContents.value.find(x => x.pageName === pageName && x.contentName === x.contentName);
    if(!marketingContent) {          
      let resp = await codeEngineClient.value.getMarketingContent(portalTypeId, pageName, contentName, currentLanguage.value);            
      if(resp.success && resp.marketingContent != null) {
        marketingContent = resp.marketingContent;        
        marketingContents.value.push(marketingContent);            
      }
    }

    if(marketingContent && marketingContent.fileData && marketingContent.mimeType) {
      let image = 'data:' + marketingContent.mimeType + ';base64,' + marketingContent.fileData;      
      return image;
    } else {
      return '';
    }
  }

  const getErrorMappingByErrorCode = function(errorCode, defaultValue, parameters) {
    let error = errorMapping.value.find(x => x.errorCode === errorCode);
    
    if(error) {
      let toReturn = error.message;
      if(parameters && parameters.length > 0) {
        for(let i = 0; i < parameters.length; i++) {                
          toReturn = toReturn.replaceAll('%%' + parameters[i].variable + '%%', parameters[i].value);
        }
      }
      return toReturn;
    } else {
      if(!defaultValue) {      
        let genericError = errorMapping.value.find(x => x.errorCode === 9999);
        if(genericError) {
          return genericError.message;
        } else {
          return 'No Generic Error Configured';
        }        
      } else {
        return defaultValue;
      }
    }
  }
  
  function init(id, url, key) {
    clientId.value = id;
    apiUrl.value = url;
    apiKey.value = key;    
    codeEngineClient.value.init(id, url, key);
  }

  async function loadPortalContent(portalTypeId) {
    let resp = await codeEngineClient.value.getPortalContent(portalTypeId);
    if(resp.success) {      
      portalContents.value = resp.portalContents;
      return true;
    } else {
      internalError.value = resp.error;
    }   
  }  

  async function loadErrorMapping(portalTypeId) {
    let resp = await codeEngineClient.value.getErrorMapping(portalTypeId);
    if(resp.success) {      
      errorMapping.value = resp.errorMappings;
      return true;
    } else {
      internalError.value = resp.error;
    }
  }

  async function loadPortalBranding(portalTypeId) {
    let resp = await codeEngineClient.value.getPortalBranding(portalTypeId);
    if(resp.success) {      
      branding.value = resp.portalBranding;
      return true;
    } else {
      internalError.value = resp.error;
    }   
  }

  async function loadPortalTheme(portalTypeId) {    
    let resp = await codeEngineClient.value.getPortalTheme(portalTypeId);    
    if(resp.success) {
      theme.value = JSON.parse(resp.portalTheme);
      return true; 
    } else {
      internalError.value = resp.error;
    }
  }
  
  return { loadPortalContent, init, getPortalContentByPageName, getPortalContentByPageAndContentName, getErrorMappingByErrorCode, loadErrorMapping, loadPortalBranding, branding, getMarketingContentByPageAndName, loadPortalTheme, theme}
})
