import { CodeEngineClient } from "./base-client";

let CodeEngineCoin2CoreClient = class extends CodeEngineClient {
  constructor() {
    super();
  }

  init(clientId, url, apiKey) {
    super.init(clientId, url, apiKey);
  }

  async getConfiguration() {
    try {
      let endpoint = "api/c2c/configuration";

      return await super.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to retrieve configuration", e);
    }
  }

  async getPortalContent() {
    try {
      let endpoint = "api/c2c/portalcontent";
      return await super.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get portal content", e);
    }
  }

  async registerUser(username, password, taxId, dateOfBirth) {
    try {
      let endpoint = "api/c2c/register";
      let data = {
        username: username,
        password: password,
        taxId: taxId,
        dateOfBirth: dateOfBirth,
      };

      return await super.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Register User", e);
    }
  }

  async getConfiguredCurrencies() {
    try {
      let endpoint = "api/c2c/currency";

      return await super.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get configured currencies", e);
    }
  }

  async getCryptoBalances(personId) {
    try {
      let endpoint = "api/c2c/balances";
      let data = {
        personId: personId,
      };

      return await super.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to get crypto balances", e);
    }
  }

  async createVaultWithdraw(
    personId,
    walletId,
    coinAmount,
    recipientAddress,
    feeAmount
  ) {
    try {
      let endpoint = "api/c2c/vaultwithdraw";
      let data = {
        personId: personId,
        walletId: walletId,
        coinAmount: coinAmount,
        recipientAddress: recipientAddress,
        feeAmount: feeAmount,
      };

      return await super.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to send coins", e);
    }
  }

  async addContactInfo(personId, newValue, newCategory) {
    let endpoint = "api/c2c/person/updatecontactinfo";
    let data = {
      personId: personId,
      value: newValue,
      category: newCategory,
    };

    return await super.completePostCall(endpoint, data);
  }

  async updateContactInfo(personId, contactId, newValue, newCategory) {
    let endpoint = "api/c2c/person/updatecontactinfo";
    let data = {
      personId: personId,
      contactId: contactId,
      value: newValue,
      category: newCategory,
    };

    return await super.completePostCall(endpoint, data);
  }

  async deleteContactInfo(personId, contactId) {
    let endpoint = "api/c2c/person/deletecontactinfo";
    let data = {
      personId: personId,
      contactId: contactId,
    };

    return await super.completePostCall(endpoint, data);
  }

  async validateIpAddress() {
    let endpoint = "api/c2c/validateip";

    return await super.completeGetCall(endpoint);
  }

  async getWalletHistory(personId, walletId) {
    let endpoint = "api/c2c/history";
    let data = {
      personId: personId,
      walletId: walletId,
    };

    return await super.completePostCall(endpoint, data);
  }

  async getEstimatedFees(personId, walletId, coinAmount, destinationAddress) {
    let endpoint = "api/c2c/fees";
    let data = {
      personId: personId,
      walletId: walletId,
      withdrawAmount: coinAmount,
      destinationAddress: destinationAddress,
    };

    return await super.completePostCall(endpoint, data);
  }

  async cancelVaultWithdraw(personId, walletId, withdrawId) {
    let endpoint = "api/c2c/cancelwithdraw";
    let data = {
      personId: personId,
      walletId: walletId,
      withdrawId: withdrawId,
    };

    return await super.completePostCall(endpoint, data);
  }

  async createVault(personId, selectedCryptoTypes, branchId) {
    try {
      let endpoint = "api/c2c/wallets";
      return await super.completePostCall(endpoint, {
        personId,
        selectedCryptoTypes,
        branchId: branchId
      });
    } catch (error) {
      throw error;
    }
  }

  async getAllWalletsByPersonId(personId) {
    try {
      let endpoint = `api/c2c/wallets/${personId}`;
      return await super.completeGetCall(endpoint);
    } catch (error) {
      throw error;
    }
  }

  async sendWalletQrCodeEmail(personId, walletSerials, email, phone) {
    let endpoint = `api/c2c/wallets/email`;
    let data = {
      personId: personId,
      walletSerials: walletSerials,
      email: email,
      phone: phone,
    };

    return await super.completePostCall(endpoint, data);
  }

  async sendWalletQrCodeSms(personId, walletSerials, email, phone) {
    let endpoint = `api/c2c/wallets/sms`;
    let data = {
      personId: personId,
      walletSerials: walletSerials,
      email: email,
      phone: phone,
    };

    return await super.completePostCall(endpoint, data);
  }

  async coreAdminSso(ssoSessionId) {
    try {
        let endpoint = 'api/c2c/admin/coresso';

        let data = {
            ssoToken: ssoSessionId
        }
        return await super.completePostCall(endpoint, data);
    } catch(e) {
        console.error('Unable to complete core sso', e);
    }
  }

  async getDb2Configuration() {
    try {
      let endpoint = `api/c2c/admin/config/db2`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve DB2 Settings', e);
    }
  }

  async testDb2Configuration(config) {
    try {
      let endpoint = `api/c2c/admin/testers/db2`;

      let data = {
        settings: config
      }

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Test DB2 Configuration', e);
    }
  }

  async updateDb2Configuration(config) {
    try {
      let endpoint = `api/c2c/admin/config/db2`;

      let data = {
        settings: config
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update DB2 Configuration', e);
    }
  }

  async getSmtpConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/smtp`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve SMTP Settings', e);
    }
  }

  async testSmtpConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/testers/smtp`;

      let data = {
        settings: config
      }

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Test SMTP Configuration', e);
    }
  }

  async updateSmtpConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/smtp`;

      let data = {
        settings: config
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update SMTP Configuration', e);
    }
  }

  async getEmailTemplates() {
    try {
      let endpoint = `api/c2c/admin/config/emailtemplates`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Email Templates', e);
    }
  }

  async updateEmailTemplate(template, templateType) {
    try {
      let endpoint = `api/c2c/admin/config/emailtemplates`;

      let data = {
        template: template,
        templateType: templateType
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to update Email Template', e);
    }
  }
  
  async getSmsConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/sms`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve SMS Settings', e);
    }
  }

  async testSmsConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/testers/sms`;

      let data = {
        settings: config
      }

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Test SMS Configuration', e);
    }
  }

  async updateSmsConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/sms`;

      let data = {
        settings: config
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update SMS Configuration', e);
    }
  }

  async getSmsTemplates() {
    try {
      let endpoint = `api/c2c/admin/config/smstemplates`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve SMS Templates', e);
    }
  }

  async updateSmsTemplate(template, templateType) {
    try {
      let endpoint = `api/c2c/admin/config/smstemplates`;

      let data = {
        template: template,
        templateType: templateType
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to update SMS Template', e);
    }
  }

  async getDigitalAssetsMainConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/digitalassetsmain`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Asset Main Settings', e);
    }
  }

  async updateDigitalAssetsMainConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalassetsmain`;

      let data = config;      

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Assets Main Configuration', e);
    }
  }

  async getValuationSourcesConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/valuationsources`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Valuation Sources Settings', e);
    }
  }

  async updateValuationSourcesConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/valuationsources`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Valuation Sources Configuration', e);
    }
  }

  async getDigitalAssetConfiguration(digitalAssetType) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/${digitalAssetType}`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Asset Configuration', e);
    }
  }

  async updateDigitalAssetConfiguration(digitalAssetType, config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/${digitalAssetType}`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Asset Configuration', e);
    }
  }

  async getDigitalAssetDepositConfiguration(digitalAssetType) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/deposit/${digitalAssetType}`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Asset Deposit Configuration', e);
    }
  }

  async updateDigitalAssetDepositConfiguration(digitalAssetType, config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/deposit/${digitalAssetType}`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Asset Deposit Configuration', e);
    }
  }

  async getDigitalAssetTransactConfiguration(digitalAssetType) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/transact/${digitalAssetType}`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Asset Transact Configuration', e);
    }
  }

  async updateDigitalAssetTransactConfiguration(digitalAssetType, config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalasset/transact/${digitalAssetType}`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Asset Transact Configuration', e);
    }
  }

  async getDigitalEndpointFeatureConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/digitalendpoint/features`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Endpoint Features Configuration', e);
    }
  }

  async updateDigitalEndpointFeatureConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalendpoint/features`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Endpint Features Configuration', e);
    }
  }

  async getDigitalEndpointStyleConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/digitalendpoint/style`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Digital Endpoint Style Configuration', e);
    }
  }

  async updateDigitalEndpointStyleConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/digitalendpoint/style`;

      let data = {
        settings: config
      }

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Digital Endpint Style Configuration', e);
    }
  }

  async getKnownExchangesListConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/knownexchanges/list`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Known Exchanges List Configuration', e);
    }
  }

  async updateKnownExchangesListConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/knownexchanges/list`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Known Exchanges List Configuration', e);
    }
  }

  async getKnownExchangesOpportunityConfiguration() {
    try {
      let endpoint = `api/c2c/admin/config/knownexchanges/opportunity`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve Known Exchanges Opportunity Configuration', e);
    }
  }

  async updateKnownExchangesOpportunityConfiguration(config) {
    try {
      let endpoint = `api/c2c/admin/config/knownexchanges/opportunity`;

      let data = config;

      return await super.completePutCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Known Exchanges Opportunity Configuration', e);
    }
  }

  async getAssetPoolData(type) {
    try {
      let endpoint = `api/c2c/admin/config/transact/pooldata/${type}`;

      return await super.completeGetCall(endpoint);
    } catch(e) {
      console.error('Unable to retrieve asset pool data', e);
    }
  }

  async simulateDigitalAssetPool(assetType, config) {
    try {
      let endpoint = `api/c2c/admin/config/transact/pooldata/simulate/${assetType}`;

      let data = config;      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Simulate Digital Asset Pool', e);
    }
  }

  async updateVaultTransactStatus(personId, vaultId, transactStatus, liquidityPoolOverride, nativePoolAmountOverride, nativeTransactionAmountOverride, usdTransactionAmountOverride, nativeDailyLimitOverride, usdDailyLimitOverride) {
    try {
      let endpoint = `api/c2c/vault/transactstatus`;

      let data = {
        personId: personId,
        walletId: vaultId,
        transactEnabled: transactStatus,
        liquidityPoolOverride: liquidityPoolOverride, 
        nativePoolAmountOverride: nativePoolAmountOverride, 
        nativeTransactionAmountOverride: nativeTransactionAmountOverride,
        usdTransactionAmountOverride: usdTransactionAmountOverride,
        nativeDailyLimitOverride: nativeDailyLimitOverride,
        usdDailyLimitOverride: usdDailyLimitOverride
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Update Transact Status', e);
    }
  }

  async quoteDigitalAssetExchange(assetType, personId, nativeAmount, usdAmount, buy) {
    try {
      let endpoint = `api/c2c/exchange/quote`;

      let data = {
        assetType: assetType,
        personId: personId,
        amountNative: nativeAmount, 
        amountUsd: usdAmount,
        buy: buy
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Quote Digital Asset Exchange', e);
    }
  }

  async purchaseDigitalAssets(personId, vaultId, shareId, nativeAmount, usdAmount, userId) {
    try {
      let endpoint = `api/c2c/exchange/purchase`;

      let data = {
        personId: personId,
        walletId: vaultId,
        shareId: shareId,
        purchaseAmountNative: nativeAmount, 
        purchaseAmountUsd: usdAmount,
        userId: userId
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Complete Digital Asset Purchase', e);
    }
  }

  async sellDigitalAssets(personId, vaultId, shareId, nativeAmount, usdAmount, userId) {
    try {
      let endpoint = `api/c2c/exchange/sell`;

      let data = {
        personId: personId,
        walletId: vaultId,
        shareId: shareId,
        sellAmountNative: nativeAmount, 
        sellAmountUsd: usdAmount,
        userId: userId
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Complete Digital Asset Sale', e);
    }
  }

  async completeDigitalAssetExchange(exchangeId, userId) {
    try {
      let endpoint = `api/c2c/exchange/complete`;

      let data = {
        exchangeId: exchangeId,
        userId: userId
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Complete Digital Asset Exchange', e);
    }
  }

  async rejectDigitalASsetExchange(exchangeId, userId) {
    try {
      let endpoint = `api/c2c/exchange/reject`;

      let data = {
        exchangeId: exchangeId,
        userId: userId
      };      

      return await super.completePostCall(endpoint, data);
    } catch(e) {
      console.error('Unable to Reject Digital Asset Exchange', e);
    }
  }
};

export { CodeEngineCoin2CoreClient };
