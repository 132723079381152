let CodeEngineClient = class {
  apiUrlField;
  clientIdField;
  apiKeyField;
  sessionId;

  constructor() { }

  init(clientId, url, apiKey) {
    this.apiUrlField = url;
    this.clientIdField = clientId;
    this.apiKeyField = apiKey;

    this.startSession = this.startSession.bind(this);
  }

  getAuthenticatedHeaders() {
    let headers = {
      "Content-Type": "application/json",
      "CODE-Engine-API-Key": this.apiKeyField,
      "CODE-Engine-Session-Id": this.sessionId,
    };
    return headers;
  }

  async completeGetCall(apiEndpoint) {
    let endpoint = this.apiUrlField + apiEndpoint;

    try {
      let resp = await fetch(endpoint, {
        method: "GET",
        headers: this.getAuthenticatedHeaders(),
      });

      if (!resp.ok) {
        return {
          success: false,
          error: resp.status,
          errorCode: resp.status,
        };
      }

      let respData = await resp.json();

      if (respData.errorCode) {
        return {
          success: false,
          error: respData.message,
          errorCode: respData.errorCode,
          errorMessage: respData.errorMessage,
        };
      }

      return respData;
    } catch (e) {
      console.error("Unable to complete GET call", e);
      return {
        success: false,
        errorCode: 9998,
      };
    }
  }

  async completePutCall(apiEndpoint, data) {
    let endpoint = this.apiUrlField + apiEndpoint;

    try {
      let resp = await fetch(endpoint, {
        method: "PUT",
        headers: this.getAuthenticatedHeaders(),
        body: JSON.stringify(data),
      });

      if (!resp.ok) {
        return {
          success: false,
          error: resp.status,
          errorCode: resp.status,
        };
      }

      let respData = await resp.json();

      if (respData.errorCode) {
        return {
          success: false,
          error: respData.message,
          errorCode: respData.errorCode,
          errorMessage: respData.errorMessage,
        };
      }

      return respData;
    } catch (e) {
      console.error("Unable to complete PUT call", e);
      return {
        success: false,
        errorCode: 9998,
      };
    }
  }

  async completePostCall(apiEndpoint, data) {
    let endpoint = this.apiUrlField + apiEndpoint;

    let resp;
    console.info("endpoint in base", endpoint);
    try {
      resp = await fetch(endpoint, {
        method: "POST",
        headers: this.getAuthenticatedHeaders(),
        body: JSON.stringify(data),
      });
      const respData = await resp.json();

      // This is a 404/500 or similar error, so we spit back the status code
      if (!resp.ok) {
        return {
          success: false,
          error: resp.status,
          errorCode: resp.status,
          errorMessage: respData.errorMessage || null,
        };
      }

      if (respData.errorCode) {
        return {
          success: false,
          error: respData.message,
          errorCode: respData.errorCode,
          errorMessage: respData.errorMessage,
        };
      }

      return respData;
    } catch (e) {
      // This is some sort of cannot connect error, send back a generic error
      console.error("Unable to complete POST call", e);
      return {
        success: false,
        errorCode: 9998,
      };
    }
  }

  async completeDeleteCall(apiEndpoint) {
    let endpoint = this.apiUrlField + apiEndpoint;

    try {
      let resp = await fetch(endpoint, {
        method: "DELETE",
        headers: this.getAuthenticatedHeaders(),
      });

      if (!resp.ok) {
        return {
          success: false,
          error: resp.status,
          errorCode: resp.status,
        };
      }

      let data = await resp.json();

      if (data.errorCode) {
        return {
          success: false,
          error: data.message,
          errorCode: data.errorCode,
        };
      }

      return data;
    } catch (e) {
      // This is some sort of cannot connect error, send back a generic error
      console.error("Unable to complete DELETE call", e);
      return {
        success: false,
        errorCode: 9998,
      };
    }
  }

  async startSession() {
    let endpoint = this.apiUrlField + "api/instance/session/start";

    let request = {};

    try {
      let resp = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CODE-Engine-API-Key": this.apiKeyField,
        },
        body: JSON.stringify(request),
      });

      if (!resp.ok) {
        return {
          success: false,
          error: resp.status,
        };
      }
      let data = await resp.json();
      if (data.sessionId) {
        this.sessionId = data.sessionId;
      } else {
        return {
          success: false,
          error: data.error,
        };
      }
      return data;
    } catch (e) { }
  }

  async login(username, password, portalTypeId) {
    try {
      let endpoint = "api/common/authentication/login";
      let data = {
        username: username,
        password: password,
        portalTypeId: portalTypeId,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Login User", e);
    }
  }

  async changeLoginPassword(username, oldPassword, newPassword, portalTypeId) {
    try {
      let endpoint = "api/common/authentication/changepassword";
      let data = {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
        portalTypeId: portalTypeId,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Login User", e);
    }
  }

  async getPortalContent(portalTypeId) {
    try {
      let endpoint =
        "api/common/portalconfiguration/portalcontent/" + portalTypeId; // This is the portalTypeId from the C# enum
      return await this.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get portal content", e);
    }
  }

  async getPortalTheme(portalTypeId) {
    try {
      let endpoint =
        "api/common/portalconfiguration/theme/" + portalTypeId; // This is the portalTypeId from the C# enum
      return await this.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get portal content", e);
    }
  }

  async getMarketingContent(portalTypeId, pageName, contentName, languageCode) {
    try {
      let endpoint =
        "api/common/portalconfiguration/marketingcontent/" +
        portalTypeId +
        "/" +
        pageName +
        "/" +
        contentName +
        "/" +
        languageCode;
      return await this.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get marketing content", e);
    }
  }

  async getPortalBranding(portalTypeId) {
    try {
      let endpoint =
        "api/common/portalconfiguration/portalbranding/" + portalTypeId; // This is the portalTypeId from the C# enum
      return await this.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get portal branding", e);
    }
  }

  async getErrorMapping(portalTypeId) {
    try {
      let endpoint =
        "api/common/portalconfiguration/errormapping/" + portalTypeId; // This is the portalTypeId from the C# enum
      return await this.completeGetCall(endpoint);
    } catch (e) {
      console.error("Unable to get portal error mapping", e);
    }
  }

  async sendSecondaryAuthRequest(
    username,
    contactId,
    action,
    loginId,
    portalTypeId
  ) {
    try {
      let endpoint = "api/common/secondaryauthentication/send";
      let data = {
        username: username,
        contactId: contactId,
        action: action,
        loginId: loginId,
        portalTypeId: portalTypeId,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Send Secondary Auth Request", e);
    }
  }

  async verifySecondaryAuthRequest(
    requestId,
    username,
    contactId,
    action,
    loginId,
    passcode,
    portalTypeId
  ) {
    try {
      let endpoint = "api/common/secondaryauthentication/verify";
      let data = {
        requestId: requestId,
        username: username,
        contactId: contactId,
        action: action,
        loginId: loginId,
        passcode: passcode,
        portalTypeId: portalTypeId,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Verify Secondary Auth Request", e);
    }
  }

  async sendForgotPasswordLink(
    username,
    phoneNumber,
    email,
    ssn,
    portalTypeId,
    applicationPath
  ) {
    try {
      let endpoint = "api/common/secondaryauthentication/sendforgotpassword";
      let data = {
        username: username,
        phoneNumber: phoneNumber,
        email: email,
        taxId: ssn,
        portalTypeId: portalTypeId,
        applicationPath: applicationPath,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Send Forgot Password Link Request", e);
    }
  }

  async validatePasswordResetLink(rid, rtk) {
    try {
      let endpoint =
        "api/common/secondaryauthentication/validateforgotpasswordlink";
      let data = {
        rid: encodeURIComponent(rid),
        rtk: encodeURIComponent(rtk),
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Send Forgot Password Link Request", e);
    }
  }

  async completeForgotPassword(requestId, newPassword, portalTypeId) {
    try {
      let endpoint =
        "api/common/secondaryauthentication/completeforgotpassword";
      let data = {
        requestId: requestId,
        newPassword: newPassword,
        portalTypeId: portalTypeId,
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Complete Forgot Password Request", e);
    }
  }

  async parseId(base64image) {
    try {
      let endpoint =
        "api/common/idparse";
      let data = {
        base64Image: base64image.split(',')[1],
      };

      return await this.completePostCall(endpoint, data);
    } catch (e) {
      console.error("Unable to Parse Id Request", e);
    }
  }

  async getCoreTableOptions(tableName, description, filterName, fieldName, maxRows) {
    try {
      let endpoint = `api/common/core/search`;

      let data = {
        tableName: tableName,
        description: description,
        filterName: filterName,
        returnLimit: maxRows,
        fieldName: fieldName
      }

      return await this.completePostCall(endpoint, data);
    } catch(e) {
      console.error("Unable to Get Core Table Options", e);
    }
  }
};

export { CodeEngineClient };
